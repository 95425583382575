import { SEOModule } from "@/src/component/cms";
import { Redirect } from "@/src/component/view";
import { StrapiCMSAPI } from "@/src/service";
import { StrapiCMSType } from "@/src/type";
import { StringHelper } from "@/src/util";
import type { GetStaticProps, NextPage } from "next";

interface Props {
  globalWebConfig: StrapiCMSType.GlobalWebConfigResponse;
  locale: string;
}

const Page404: NextPage<Props> = ({ globalWebConfig }) => {
  return (
    <>
      <SEOModule
        path={"/404"}
        globalWebConfig={globalWebConfig}
        name="Page Missing"
        dashedName={true}
      />
      <Redirect />
    </>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  const locale: string = StringHelper.checkIsStringOrIsNull(
    context?.params?.locale,
    "en"
  );
  const GlobalWebConfigResponse = await StrapiCMSAPI.getGlobalWebConfig(locale);

  return {
    props: {
      globalWebConfig: GlobalWebConfigResponse.response,
      locale,
    },
  };
};

export default Page404;
